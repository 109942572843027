module.exports = [{
      plugin: require('/builds/zero-1/zero-1-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1600},
    },{
      plugin: require('/builds/zero-1/zero-1-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-124156945-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/builds/zero-1/zero-1-website/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false},
    },{
      plugin: require('/builds/zero-1/zero-1-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
