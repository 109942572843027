// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-tsx": () => import("/builds/zero-1/zero-1-website/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-pages-404-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-corporate-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/corporate.tsx" /* webpackChunkName: "component---src-pages-corporate-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-labs-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/labs.tsx" /* webpackChunkName: "component---src-pages-labs-tsx" */),
  "component---src-pages-news-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-team-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("/builds/zero-1/zero-1-website/src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

